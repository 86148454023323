<template>
  <div class="square-toggle">
    <div class="square-toggle__title">Select content type</div>
    <div class="square-toggle__list">
      <div :class="$t(`square-toggle__list__item ${this.toggleInType === 'html' ? 'active' : ''}`)" @click='toggleActive("html")'>
        html
      </div>
      <div :class="$t(`square-toggle__list__item ${this.toggleInType === 'iframe' ? 'active' : ''}`)" @click='toggleActive("iframe")'>
        iframe
      </div>
      <div :class="$t(`square-toggle__list__item ${this.toggleInType === 'json' ? 'active' : ''}`)" @click='toggleActive("json")'>
        json
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TypeToggle",
    data() {
      return {
        toggleInType: "html",
      };
    },
    methods: {
      onClick() {
        this.$emit("save", this.toggleInType);
      },
      toggleActive(inType) {
        this.toggleInType = inType;
        this.$emit("update", inType);
      },
    },
  };
</script>

<style lang="scss">

  .square-toggle {
    &__title {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }

    .va-button {
      margin-left: 16px;
    }

    &__list {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      z-index: 12;
      border: 1px solid #e5ebea;
      border-radius: 4px;
      padding: 4px;
      font-size: 16px;
      line-height: 22px;
      transition: 0.28s;

      &__item {
        padding: 8px 24px;
        cursor: pointer;
        border-radius: 4px;
        transition: 0.28s;
        color: #8d9ca8;

        &.active {
          color: #000000;
          background-color: #e5ebea;
          cursor: default;
        }
      }
    }
  }
</style>
