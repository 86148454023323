<template>

  <div class="popup--preview">
    <div class="popup--preview__box">
      <div class="popup--preview__box__header">
        <TypeToggle @update="updateToggle"/>
        <div class="modal-select-block">
          <div class="select-block__title">Select retailer</div>
          <va-select
            v-model="selectedRetailer"
            :label="$t('Retailers')"
            :options="modalRetailers"
            icon="search"
            placeholder="Type or scroll and choose"
            searchable
          />
        </div>
        <div class="modal-select-block">
          <div class="select-block__title">Select size</div>
          <div class="select-block__sizes">
            <i :class="['fa fa-desktop', activeType === 'desktop' ? 'active' : '']"
               v-on:click="changeSize('desktop')"></i>
            <i :class="['fa fa-laptop', activeType === 'laptop' ? 'active' : '']" v-on:click="changeSize('laptop')"></i>
            <i :class="['fa fa-tablet', activeType === 'tablet' ? 'active' : '']" v-on:click="changeSize('tablet')"></i>
            <i :class="['fa fa-mobile', activeType === 'mobile' ? 'active' : '']" v-on:click="changeSize('mobile')"></i>
          </div>
        </div>
        <button class="download--report__button" @click="downloadPdfContent">Download preview</button>
        <div class="popup--preview__box__close">
          <IconClosePopup :click="close"/>
        </div>
        <va-progress-bar v-if="loading"
                         :style="{width: '100%', position: 'absolute', bottom: 0, left: 0, backgroundColor: '#ffffff'}"
                         indeterminate></va-progress-bar>
      </div>
      <div v-if="preview" class="popup--preview__box__rich">
        <iframe v-if="inType !== 'html'" :src='preview' :style="{'max-width': activeSize}" class="iframe"></iframe>
        <div v-if="inType === 'html'" :style="{'max-width': activeSize}" class="iframe" v-html="preview"></div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import API from "../../services/API/API";
import IconClosePopup from "../ui/icons/IconClosePopup";
import TypeToggle from "../toggle/TypeToggle";
import ToastifyErrorSave from "../ui/toastify/ToastifyErrorSave";

export default {
  name: 'ModalPreview',
  components: {TypeToggle, IconClosePopup},
  props: {
    epack: {
      type: Object,
      default: () => {
      },
    },
    epackageID: {
      type: String,
      default: () => "",
    },
    retailersInfo: {
      type: Array,
      default: () => [],
    },
    retailers: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isOpen: false,
      selectedRetailer: "",
      modalRetailers: [],
      inType: "html",
      preview: null,
      activeSize: "1200px",
      activeType: "desktop",
      loading: true,
      sizes: {
        "desktop": "1200px",
        "laptop": "991px",
        "tablet": "768px",
        "mobile": "320px",
      },
    };
  },

  created() {
    this.modalRetailers = this.retailersInfo
      .filter(retailer => this.retailers.includes(retailer.id) && this.epack[`${retailer.name.toLowerCase()}_status`] !== "no-map")
      .map(({id, name}, index) => {
        let retailer = {id, text: name};
        if (!index) {
          this.selectedRetailer = retailer;
        }
        return retailer;
      });
  },

  watch: {
    selectedRetailer: function () {
      this.getPreview();
    },

    preview: function () {
      if (this.inType === "html") {
        setTimeout(this.updateHtmlSize, 10);
      }
    },
  },

  methods: {
    changeSize(type) {
      this.activeType = type;
      this.activeSize = this.sizes[type];
      this.updateHtmlSize();
    },

    updateHtmlSize() {
      let stream = document.querySelector("#stream-container");
      if (stream) {
        stream.classList.remove("small-pc", "tablet", "mobile");
        if (this.activeType === "laptop") {
          stream.classList.add("small-pc");
        }
        if (this.activeType === "tablet") {
          stream.classList.add("small-pc", "tablet");
        }
        if (this.activeType === "mobile") {
          stream.classList.add("small-pc", "tablet", "mobile");
        }
      }
    },

    updateToggle(inType) {
      this.inType = inType;
      this.getPreview();
    },

    getPreview() {
      if (!this.selectedRetailer) {
        return false;
      }
      this.loading = true;
      API.APIGet(`${process.env.VUE_APP_API_URL}/epackage/${this.epackageID}/${this.selectedRetailer.id}/${this.inType}/preview`,
        {},
        preview => {
          if (this.inType === "html") {
            axios.get(preview)
              .then(response => {
                this.preview = response.data;
              });
          }
          this.preview = preview;
          this.loading = false;
        },
        error => {
          const {code} = error.response;
          let message = code === 500 ? "Something wrong, please try again later" : "No rich found";
          this.showError(message);
          this.preview = null;
          this.loading = false;
        });
    },

    close() {
      this.loading = true;
      this.$emit("close", false);
    },

    showError(text) {
      this.$toast.error(
        {
          component: ToastifyErrorSave,
          props: {text},
        }, {
          toastClassName: "Vue-Toastification__toast--error",
          position: 'bottom-left',
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1.65,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: false,
          rtl: false,
        });
    },

    downloadPdfContent() {
      const WinPrint = window.open('', '', 'width=920');
      WinPrint.document.write('<html><head>');
      WinPrint.document.write(document.querySelector('head').innerHTML);
      WinPrint.document.write('<style>*{page-break-inside: avoid;page-break-before: avoid; page-break-after: avoid; max-width: 750px;}</style>')
      WinPrint.document.write('</head>');
      WinPrint.document.write('</html>');
      WinPrint.document.close();
      const body = WinPrint.document.createElement('div')
      const elem = document.querySelector('.iframe').cloneNode(true)
      body.appendChild(elem)
      WinPrint.document.querySelector('html').appendChild(body)
      setTimeout(() => {
        WinPrint.focus();
        WinPrint.document.querySelector('body').remove()
        WinPrint.print();
        this.$emit('disable');
      }, 3000)
      WinPrint.onafterprint = WinPrint.close;
    }
  },
};
</script>

<style lang="scss">
.modal-select-block {
  margin-left: 24px;
  width: 30%;
}

.download--report__button {
  justify-self: center;
  align-self: center;
  width: 185px;
  height: 48px;
  background-color: white;
  color: black;
  font-weight: 550;
  border: 1px solid #d6dee2;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 10px;
  cursor: pointer;
}
</style>
